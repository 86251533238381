@import "node_modules/bootstrap/less/bootstrap";

// @import "./spark/spark";
@import "./../../../vendor/laravel/spark/resources/assets/less/spark";

// custom
.spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 0 auto;
}

.double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #000;
    opacity: 0.1;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

h4 {
    padding-top: 30px;
}

@-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
    0%, 100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    } 50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}



.navbar-inverse {
    border-color: #fff;
}

.panel {
    border: none;
    border-radius: 1px;
    box-shadow: none;
}

.panel-default > .panel-heading {
    border: 0;
    background-color: #f5f8fa;
    font-weight: 500;
    color: #999;
}

.col-md-8 .panel-default > .panel-heading,
.tab-pane .panel-default > .panel-heading {
    background-color: #fff;
    // color: #333;
}

.tab-pane .panel {
    box-shadow: 0px 2px 4px rgba(0,0,0,0.18);
}

.spark-settings-stacked-tabs li a:active,
.spark-settings-stacked-tabs li a:hover,
.spark-settings-stacked-tabs li a:link,
.spark-settings-stacked-tabs li a:visited {
    background-color: #f5f8fa;
}

.spark-settings-stacked-tabs a {
    border: none;
}

.spark-settings-stacked-tabs a:hover {
    color: #26292b;
}

.spark-settings-stacked-tabs li.active a {
    border-left: none;
    background-color: #fcfcfc;
}

.spark-screen .profile-photo-preview {
    border-radius: 0px;
}

.form-control {
    border-radius: 0px;
}

.btn {
    border-radius: 0px;
}

.btn:hover {
    box-shadow: 0 2px 4px rgba(0,0,0,0.18);
    transition: all 0.5s ease;
}

.btn-primary:hover {
    background-color: #3097D1;
}

.alert {
    border-radius: 0px;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.18);
    border: none;
}
